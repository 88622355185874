<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>    
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col v-if="mostrarProceso" class="ma-0 pa-0 d-flex justify-space-between cssTitleProgress" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span>{{nominaPorcentaje<100?'Timbrado de nómina en proceso':'Timbrado de nómina finalizado'}}</span> 
                    <span>{{'Procesado '+nominaProcesados+' de '+nominaTotal}}</span>
                </v-col>
                <v-col v-if="mostrarProceso" class="ma-0 pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-progress-linear
                        :value="nominaPorcentaje"
                        height="10"
                        rounded
                        background-color="#DADADA"
                        :color="nominaPorcentaje<100?'#F2C94C':'#219653'"
                    >
                        <span class="cssTitleProgress">{{ Math.ceil(nominaPorcentaje) }}%</span>
                    </v-progress-linear>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <simple-table
                        ref="tablageneral"
                        :isSelectable="false"
                        :selected="selected_general"
                        @selectAllEvent="selectAll"
                        :rows="itemsEmpleadosTabla"
                        :columns="columns"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                        :valCheck="'empleado_id'"
                        :idTabla="'idTablaDesacumulados'"
                    >
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.empleado_id" v-for="(item, index) in data">
                                <td v-if="false"style="width:115px !important; ">
                                    <div >
                                        <v-checkbox class="mb-5" primary hide-details :value="item.empleado_id" v-model="selected_general" ></v-checkbox>
                                    </div>
                                </td> 
                                <td><div>{{item.empleado}}</div></td>
                                <td><div>{{formatoMoneda(item.total_percepciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.total_deducciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.neto_pagar)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="timbrar(item)" class="botonHover" icon fab dark small >
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton">mdi-bell-ring-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Timbrar</span>
                                        </v-tooltip>
                                        <v-tooltip top v-if="item.retimbrar">
                                            <template v-slot:activator="{ on }">
                                                <v-img
                                                    v-on="on"
                                                    class="mr-n8 mt-2 ml-3"
                                                    style="margin-bottom:2px"
                                                    max-height="23"
                                                    max-width="23"
                                                    src="/static/svg/signoExclamacion.svg"
                                                ></v-img>
                                            </template>
                                            <span>Se requiere retimbrar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogRetimbrar" persistent max-width="600px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                    <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                        <div class="headerModal-filter">
                            <h2 class="titleModalRegistro">{{ tituloModal }}</h2>
                        </div>
                    </v-card-title>

                    <v-card-text class="card_texto">
                        <v-container grid-list-md id="contenedor">
                            <v-row class="px-0">
                                <v-col v-if="isRetimbrar == true" cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                    <v-radio-group
                                        v-model="claveMotivo"
                                        mandatory
                                    >
                                        <v-radio
                                            label="Clave: 01: Comprobante emitido con errores con relación."
                                            value="01"
                                            class="radioLabel"
                                        ></v-radio>
                                        <v-radio
                                            label="Clave: 02: Comprobante emitido con errores sin relación."
                                            value="02"
                                            class="radioLabel"
                                        ></v-radio>
                                        <v-radio
                                            label="Clave 03: No se llevó a cabo la operación"
                                            value="03"
                                            class="radioLabel"
                                        ></v-radio>
                                        <p v-if="claveMotivo=='03'" class="pNota mb-0">Nota: Una vez aplicado el motivo de cancelación con clave "03", 
                                            el sistema no permitirá realizar ningún timbre, ya que este supuesto 
                                            aplica cuando se facturó una operación que no se concreta.
                                        </p>
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                    <VuetifyDatePicker
                                        outlined
                                        rules="required"
                                        :valorDefecto="fecha_pago"
                                        v-model="fecha_pago"
                                        label="Fecha de pago"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaPago"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end card_accion">
                        <button
                            class="btnCerrar"
                            @click="cerrarModal()" 
                            :disabled="isSaving"
                        >
                            Cerrar
                        </button>
                        <v-btn
                            class="btnGuardar"
                            @click="retimbrar()"
                        >
                            Enviar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog v-model="dialogMensaje" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalTimbre" style="display: none;"></v-btn>
                </template>
                <v-card class="borde-card">
                        <v-card-title class="justify-center align-center text-center card_titulo">
                            <div>
                                <img :src="'/static/icon/alert_success.png'" class="imgModal pb-5" />
                                <h2 class="titleModalTimbre pb-5">Proceso de timbre terminado</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <v-container  grid-list-md id="contenedor">
                                <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                    El proceso de timbre terminó satisfactoriamente.
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 justify-center align-center text-center textTimbre">
                                    Procesados: {{procesados}} <br>
                                    Con error: {{conError}}
                                </v-col>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="card_accion pt-0">
                            <v-container  grid-list-md class="ma-0 pa-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                    <v-btn 
                                        class="btnModalAlert"
                                        color='#1E2245'
                                        dark
                                        @click="cerrarModalMensaje()"
                                    >
                                        ok
                                    </v-btn>
                                </v-col>
                            </v-container>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import Notify from '@/plugins/notify';
import Lottie from 'vue-lottie';
import animationData from '@/animations/cliker.json';
import LoadingCliker from '@/components/LoadingCliker';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

import env from "@/plugins/enviroment";
import Echo from "laravel-echo";
window.Pusher = require('pusher-js');

export default {
    components: {
        "simple-table": DatatableSimple,
        'lottie': Lottie,
        'loading-cliker':LoadingCliker,
        VuetifyDatePicker,
    },
    data() {
        return {
            url         : "nominas-timbres/detalle",
            columns     : [
                {
                    label: 'Empleado',
                    name: 'nombre_completo',
                    filterable: false
                },
                {
                    label: 'Total percepciones',
                    name: 'total_percepciones',
                    filterable: false
                },
                {
                    label: 'Total deducciones',
                    name: 'total_deducciones',
                    filterable: false
                },
                {
                    label: 'Neto a pagar',
                    name: 'neto_pagar',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            itemsLinks      : [
                {
                    text: 'Nóminas desacumuladas',
                    disabled: false,
                    to: '/desacumulados',
                },
                {
                    text: 'Detalle del periodo',
                    disabled: true,
                    to: '/detalleDesacumulado',
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc",
                nomina_id : null
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : "",
            timbreNomina            : null,
            checkAll                : false,
            selected_general        : [],
            unselected_general      : [],
            masivo                  : false,
            defaultOptions          : { animationData: animationData },
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
            itemsEmpleadosTabla     : [],

            nominaPorcentaje        : 0,
            nominaTotal             : 0,
            nominaProcesados        : 0,
            dialogMensaje           : false,
            procesados              : 0,
            conError                : 0,
            mostrarProceso          : false,
            dialogRetimbrar         : false,
            claveMotivo             : '01',
            datosRegistro           : null,
            fecha_pago              : null,
            isRetimbrar             : false,
        }
    },
    watch:{
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;

            this.isLoading  = false;
            //setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.dialogRetimbrar = false;
            this.resetValues();
        },
        resetValues() {

            this.nominaPorcentaje   = 0;
            this.nominaTotal        = 0;
            this.nominaProcesados   = 0;
            this.dialogMensaje      = false;
            this.procesados         = 0;
            this.conError           = 0;
            this.dialogRetimbrar    = false;
            this.claveMotivo        = '01';
            this.datosRegistro      = null;
           
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        /**
         * @method formatoMoneda Este método sirve para formatear el valor a formato moneda en la tabla principal.
         * @description Este método sirve para formatear el valor a formato moneda en la tabla principal.
         */
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        /**
         * @method selectAll Este método se realiza al dar clic en el check que esta en el header de la tabla principal.
         * @description Este método se realiza al dar clic en el check que esta en el header de la tabla principal. Se selecciones todos los check o se deseleccionan.
         * Se ejecuta con una emit del componente tabla.
         * @param {boolean} isSelected variable para saber si check se esta seleccionado o no.
         * @param {object} items variable donde vienen todos los items de los empledos.
         */
        selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },
        
        timbrar(data){

            this.isRetimbrar = data.retimbrar;

            
            if(data.retimbrar == true) {
                this.tituloModal = "Seleccionar motivo de cancelación para enviar a retimbrar."
            }
            else {
                this.tituloModal = "Timbrar."
            }

            this.fecha_pago = data.fecha_pago
            this.datosRegistro = data
            this.dialogRetimbrar = true;
        },
        
        retimbrar(){
            if(this.isRetimbrar == true) {
                this.datosRegistro.tipo_retimbrar = this.claveMotivo;
            }

            this.datosRegistro.fecha_pago = this.fecha_pago

            let array = []
            array.push(this.datosRegistro)

            let param = {
                data: array,
                acumulado:false
            }

            apiCalculoNomina.timbraRetimbrarDesacumulados(param).then(response => {
                if(response.status === 202){
                    if(typeof response.data.message === 'string'){
                        Notify.ErrorToast(response.data.message +" Favor de verificar el detalle");
                    }else{
                        let error = Object.values(response.data.message);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        leyenda += " Favor de verificar el detalle";
                        Notify.ErrorToast(leyenda);
                    }

                }
                if(response.status === 200){
                    Notify.Success("Timbrado", "El registro se envío a timbrar");
                }
                this.dialogRetimbrar = false;
            })
            .catch(err => {
                this.dialogRetimbrar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },

        detalleTimbre(){
            this.$session.remove("detalleTimbre");
            this.$session.set('detalleTimbre',this.timbreNomina);
            this.$router.push('/detalleTimbre');
        },
        async getEmpleados(){
            await apiCalculoNomina.getEmpleadosDesacumulados(this.timbreNomina.id).then(async response => {
                this.itemsEmpleadosTabla = await response.data.filter(item =>item.cancelado != true);
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        socketProcesandoNomina(){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: 'ASDASD2121',
                wsHost: env.serverURL.url,
                wsPort: env.pusherPort,
                wssPort: env.pusherPort,
                forceTLS: env.serverURL.tls,
                disableStats: true,
                enabledTransports: ['ws', 'wss']
            });

            window.Echo.channel(`progreso-nomina-${this.timbreNomina.id}`).listen('NominaTimbreProgress', (e) => {
                this.nominaPorcentaje   = e.message.porcentaje;
                this.nominaTotal        = e.message.total;      
                this.nominaProcesados   = e.message.procesados;
                if(e.message.porcentaje == 100){
                    this.procesados = e.message.procesados;
                    this.conError   = e.message.errores;
                    this.dialogMensaje = true;
                    setTimeout(()=>{ this.clickOverlayProceso() }, 5000);
                }

            });
        },
        clickOverlayProceso(){
            this.getEmpleados();
            this.mostrarProceso= false;
        },
        cerrarModalMensaje(){
            this.dialogMensaje = false;
            this.procesados = 0;
            this.conError   = 0;
            this.mostrarProceso= false;
        },
    },
    beforeCreate(){
        if(!this.$session.get('detalleDesacumulado')){
            this.$router.push('/dashboard');
        }
    },
    async created() {
        this.timbreNomina = this.$session.get('detalleDesacumulado');
        this.socketProcesandoNomina()
        await this.getEmpleados();
    },
}
</script>

<style scoped>
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
    .titleModalRegistro {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }
    .titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .cssTitleProgress{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
</style>
